import React, { useCallback, useEffect, useState } from "react";
import LabelInput from "../../../components/label";
import InputBox from "../../../components/inputbox";
import {
  useLazyGetSadminDataQuery,
  useSAdminEditProfileMutation,
} from "../../../services/api";
import { UploadMedia } from "../../../services/uploadmedia";
import { Spin } from "antd";
import { errorToast, successToast } from "../../../helpers/showToast";
import { isAlphabete, validMobileNo } from "../../../utils/validations";
import { STORAGE_KEYS, storeData } from "../../../constants/storage";
import useAuth from "../../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../reducers/authSlice";

const EditForm = () => {
  const [EditProfile] = useSAdminEditProfileMutation();
  const [getDetails] = useLazyGetSadminDataQuery();
  const dispatch = useDispatch();
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [load, setLoad] = useState(false);
  const [profilePic, SetProfilePic] = useState(null);
  const [profileImg, setProfileImg] = useState();
  const [required, setRequire] = useState(false);
  const userData = useAuth();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log("file: ", file);
    if (file) {
      SetProfilePic(file);
      setProfileImg(URL.createObjectURL(file));
    }
  };

  const saveProfile = async () => {
    if (!fname || !lname || !email || !phone || !validMobileNo(phone)) {
      setRequire(true);
      return;
    }
    let mediaResponse;
    let proMedia;
    let response;
    try {
      setLoad(true);

      if (profilePic) {
        mediaResponse = await UploadMedia(profilePic);
        proMedia = await mediaResponse.data.find((media) => media.id);
      }

      let body = {
        email: email,
        first_name: fname,
        last_name: lname,
        phone_no: phone,
        profile_picture: profilePic ? proMedia?.id : null,
      };

      response = await EditProfile(body);
      if (response?.data?.code === 200) {
        const userData = await getDetails();
        console.log("userData: ", userData?.data?.data);
        storeData(STORAGE_KEYS.userData, userData?.data?.data);
        dispatch(
          setCredentials({
            user: userData?.data?.data,
          })
        );
        successToast(response?.data?.message);
      }
    } catch (error) {
      errorToast(response?.error?.data?.message);
      console.log("error: ", error);
    } finally {
      setLoad(false);
      setRequire(false);
      fetchData();
    }
  };

  const fetchData = useCallback(() => {
    try {
      setLoad(true);
      setFname(userData?.first_name);
      setLname(userData?.last_name);
      setEmail(userData?.email);
      setPhone(userData?.phone_no);
      setProfileImg(userData?.profile_picture?.media_file_url);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [userData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const phNumber = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    setPhone(numericValue);
  };

  const handleFname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setFname(input);
    }
  };

  const handleLname = (e) => {
    const input = e.target.value;
    if (input === "" || isAlphabete(input)) {
      setLname(input);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveProfile(e);
    }
  };
  return (
    <>
      <Spin spinning={load} size="medium">
        <div className="shd_bx">
          <div className="fld_rhs text_center">
            <figure>
              <img
                src={profileImg ? profileImg : "/images/frame.png"}
                alt="profile"
              />
              <InputBox type="file" onChange={handleFileUpload} />
            </figure>
          </div>
          <div className="fld_lhs d_flx">
            <div className="w_49">
              <LabelInput Name="First Name :" />
              <InputBox
                placeholder="Enter your First Name"
                type="text"
                value={fname}
                onChange={handleFname}
                required={required}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="w_49">
              <LabelInput Name="Last Name :" />
              <InputBox
                placeholder="Enter your Last Name"
                type="text"
                value={lname}
                onChange={handleLname}
                required={required}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="w_49">
              <LabelInput Name="Email :" />
              <InputBox
                placeholder="Enter your Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required={required}
                disabled={true}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="w_49">
              <LabelInput Name="Phone Number (optional) :" />
              <InputBox
                placeholder="Enter your contact number"
                type="text"
                value={phone}
                onChange={phNumber}
                required={required}
                onKeyDown={handleKeyPress}
              />
            </div>
            <button type="button" className="save_btn" onClick={saveProfile}>
              Save Changes
            </button>
          </div>
        </div>
      </Spin>
    </>
  );
};
export default EditForm;

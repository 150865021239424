/* eslint-disable jsx-a11y/img-redundant-alt */
import SearchBox from "../../../components/search";
import AddNotification from "../../../components/addnotification";
import { useAllNotificationMutation } from "../../../services/notifications";
import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import {
  Empty,
  Pagination,
  Skeleton
} from "antd";

const NotificationList = () => {

  const [open, setOpen] = useState(false);
  const [ntfData, setNtfData] = useState([]);
  const [search, setSrchBot] = useState("");
  const [allNtf] = useAllNotificationMutation();
  const [load, setLoad] = useState(false)
  const [totalCnt, setTotalCnt] = useState()
  const [current, setCurrent] = useState();

  const onChange = (page) => {
    setCurrent(page);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoad(true)
      let body = {
        page: current,
        length: 20,
        search_value: search ? search : "",
      }
      const response = await allNtf(body);
      console.log('response: ', response?.data?.data);
      setNtfData(response?.data?.data);
      setTotalCnt(response?.data?.total_count)
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoad(false)
    }
  }, [allNtf, search, current])

  useEffect(() => {
    fetchData();
  }, [fetchData])

  const handleCloseModal = () => {
    setOpen(false);
    fetchData();
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1> Manage Notification</h1>
          <div className="srch_add">
            <SearchBox
              value={search}
              onChange={(e) =>
                setSrchBot(e.target.value)} />
            <button
              type="button"
              className="add_btn"
              onClick={() =>
                setOpen(true)}>
              Add Notification
            </button>
          </div>
        </div>
        <div className="table_box">
          <Skeleton
            loading={load}
            active
            block={true} >
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Send To</th>
                  <th>Title</th>
                  <th>Notification Type</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {ntfData?.length
                  ? (ntfData?.map((ntf, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{ntf?.notification_for}</td>
                      <td>{ntf?.title}</td>
                      <td>{ntf?.notification_type}</td>
                      <td>{ntf?.message}</td>
                    </tr>
                  )))
                  : !load
                    ? <Empty />
                    : null}
              </tbody>
            </table>
            {ntfData?.length
              && totalCnt > 10 ? (
              <Pagination
                defaultCurrent={1}
                current={current}
                onChange={onChange}
                total={totalCnt} />
            ) : null}
          </Skeleton>
        </div>
      </div>
      <AddNotification
        setOpen={setOpen}
        open={open}
        onClose={handleCloseModal} />
    </>
  );
};
export default NotificationList;

/* eslint-disable jsx-a11y/img-redundant-alt */
import "react-tabs/style/react-tabs.css";
import { DaysCount } from "../../../utils/daysCount";
import { useLazyClientByIdQuery } from "../../../services/client";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Empty, Spin } from "antd";
import {
  useLazyFallbackEmailQuery,
  useLazyGetCsatQuery,
  useLazyServerKeyQuery,
  useLazyShiftTimigQuery,
  useLazySubscriptionHisQuery,
  useLazyThemeManagementQuery,
} from "../../../services/clientview";

const ClientDetails = () => {
  let history = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [ClientById] = useLazyClientByIdQuery();
  const [themeManagement] = useLazyThemeManagementQuery();
  const [fallbackEmail] = useLazyFallbackEmailQuery();
  const [subsHistory] = useLazySubscriptionHisQuery();
  const [csatData] = useLazyGetCsatQuery();
  const [shiftTiming] = useLazyShiftTimigQuery();
  const [severKey] = useLazyServerKeyQuery();
  const [clientData, setClientData] = useState(null);
  const [load, setLoad] = useState(false);
  const [tadLoad, setTabLoad] = useState(false);
  const [theme, setTheme] = useState(null);
  const [fallback, setFallback] = useState(null);
  const [sub, setSub] = useState(null);
  const [csat, setCsat] = useState(null);
  const [shift, setShift] = useState(null);
  const [serverKey, setServerKey] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoad(true);
        const response = await ClientById(state?.key);
        const responseData = response?.data?.data;
        console.log(state.key, "clinetData : ", response?.data?.data);
        handleTabSelection(0);
        setClientData(responseData);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchData();
  }, [ClientById, state]);

  function ClientsPage() {
    history("/clients");
  }

  function ManageChat() {
    history(`/managechat/${state?.key}`);
  }

  const fetchTheme = async () => {
    try {
      setTabLoad(true);
      const response = await themeManagement(state?.key);
      console.log("theme: ", response?.data?.data);
      setTheme(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTabLoad(false);
    }
  };

  const fetchFallBackEmail = async () => {
    try {
      setTabLoad(true);
      const response = await fallbackEmail(state?.key);
      console.log("fallback: ", response?.data?.data);
      setFallback(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTabLoad(false);
    }
  };

  const fetchSubsHistory = async () => {
    try {
      setTabLoad(true);
      const response = await subsHistory(state?.key);
      console.log("subs: ", response?.data?.data);
      setSub(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTabLoad(false);
    }
  };

  const fetchCsat = async () => {
    try {
      setTabLoad(true);
      const response = await csatData(state?.key);
      console.log("csat: ", response?.data?.data);
      setCsat(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTabLoad(false);
    }
  };

  const fecthFCM = async () => {
    try {
      setTabLoad(true);
      const response = await severKey(state?.key);
      console.log("shift: ", response?.data?.data);
      setServerKey(response?.data?.data?.fcm_server_key);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTabLoad(false);
    }
  };

  const fecthAgentAvailability = async () => {
    try {
      setTabLoad(true);
      const response = await shiftTiming(state?.key);
      console.log("shift: ", response?.data?.data);
      setShift(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTabLoad(false);
    }
  };

  const handleTabSelection = (key) => {
    switch (key) {
      case 0:
        fetchSubsHistory();
        break;
      case 1:
        fetchTheme();
        break;
      case 2:
        fetchFallBackEmail();
        break;
      case 3:
        fetchCsat();
        break;
      case 4:
        fecthFCM();
        break;
      case 5:
        fecthAgentAvailability();
        break;
      default:
        fetchSubsHistory();
    }
  };

  function convertTimeTo12HourFormat(timeStr) {
    const inputTime = new Date("1970-01-01T" + timeStr + "Z");
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const outputTimeStr = inputTime.toLocaleTimeString("en-US", options);
    return outputTimeStr;
  }

  const handleWidgetPosition = (key) => {
    switch (key) {
      case 1:
        return "Bottom right";
      case 2:
        return "Top right";
      case 3:
        return "Middle right";
      case 4:
        return "Botton left";
      case 5:
        return "Top left";
      case 6:
        return "Middle left";
      default:
        return "Bottom right";
    }
  };

  const handleLauncher = (key) => {
    console.log("key: ", key);
    switch (key) {
      case 1:
        return (
          <figure>
            <img src="./images/chat1.svg" alt="monitor" />
          </figure>
        );
      case 2:
        return (
          <figure>
            <img src="./images/chat2.svg" alt="monitor" />
          </figure>
        );
      case 3:
        return (
          <figure>
            <img src="./images/chat3.svg" alt="monitor" />
          </figure>
        );
      case 4:
        return (
          <figure>
            <img src="./images/chat4.svg" alt="monitor" />
          </figure>
        );
      default:
        return (
          <figure>
            <img src="./images/chat1.svg" alt="monitor" />
          </figure>
        );
    }
  };

  const handleType = (type) => {
    switch (type) {
      case "PURCHASED":
        return "green_text";
      case "EXPIRED":
        return "red_text";
      case "RENEWED":
        return "orange_text";
      case "NEW":
        return "blue_text";
      default:
        return "red_text";
    }
  };
  return (
    <>
      <Spin size="large" spinning={load}>
        <div className="shd_bx w_100 chat_bot">
          <div className="title_bar d_flx">
            <h1>Client Details</h1>
            <div className="srch_add">
              <button type="button" className="view_btn" onClick={ManageChat}>
                View Chat
              </button>
              <button type="button" className="add_btn" onClick={ClientsPage}>
                Back
              </button>
            </div>
          </div>
          <div className="details_page">
            <div className="details_lhs">
              <figure>
                <img
                  src={
                    clientData?.profile_picture?.media_file_url
                      ? clientData?.profile_picture?.media_file_url
                      : "./images/user.jpeg"
                  }
                  alt="image"
                />
              </figure>
            </div>
            <div className="details_rhs">
              <h2>
                <i className="fa-solid fa-user"></i>
                {`${clientData?.first_name} ${clientData?.last_name}`}
              </h2>
              <h3>
                <i className="fa-solid fa-envelope"></i>
                {clientData?.email ? clientData?.email : "N/A"}
              </h3>
              <h4>
                <i className="fa-solid fa-phone"></i>
                {clientData?.phone_no ? clientData?.phone_no : "N/A"}
              </h4>
              <h5>
                <i className="fa-solid fa-shapes"></i>
                {clientData?.trial_period
                  ? DaysCount(clientData?.trial_period)
                  : "N/A"}
              </h5>
              <h6>
                <i className="fa-solid fa-gift"></i>
                {clientData?.subscription?.name
                  ? clientData?.subscription?.name
                  : "N/A"}
              </h6>
              <div className="subs_history">
                <Tabs onSelect={(index) => handleTabSelection(index)}>
                  <TabList>
                    <Tab>Subscription History</Tab>
                    <Tab>Theme Management</Tab>
                    <Tab>Fallback Emails</Tab>
                    <Tab>Customer Survey</Tab>
                    <Tab>FCM</Tab>
                    <Tab>Agent Availability</Tab>
                  </TabList>
                  <TabPanel>
                    <Spin spinning={tadLoad} size="medium">
                      <div className="table_box">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Duration</th>
                              <th>Price</th>
                              <th>Purchased date</th>
                              <th>Type</th>
                              {/* <th>Trial Period</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {sub?.length ? (
                              sub?.map((data, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{data?.subscription_name}</td>
                                  <td>
                                    {data?.duration
                                      ? DaysCount(data?.duration)
                                      : "N/A"}
                                  </td>
                                  <td>{`$${data?.price}`}</td>
                                  <td>{data?.bought_subscription_date}</td>
                                  <td>
                                    <span
                                      className={handleType(
                                        data?.subscription_type
                                      )}
                                    >
                                      {data?.subscription_type}
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <Empty />
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Spin>
                  </TabPanel>
                  <TabPanel>
                    <Spin spinning={tadLoad} size="medium">
                      <div className="table_box">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Background Color</th>
                              <th>Welcome Image</th>
                              <th>Online Status</th>
                              <th>Widget Positions</th>
                              <th>Launcher</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>
                                {theme?.back_ground_color
                                  ? theme?.back_ground_color
                                  : "N/A"}
                              </td>
                              <td>
                                {theme?.welcome_image
                                  ? theme?.welcome_image === 0
                                    ? "Agent Collage"
                                    : "Client Logo"
                                  : "N/A"}
                              </td>
                              <td>
                                {theme?.online_status
                                  ? theme?.online_status
                                  : "N/A"}
                              </td>
                              <td>
                                {theme?.widget_position
                                  ? handleWidgetPosition(theme?.widget_position)
                                  : "N/A"}
                              </td>
                              <td>
                                {theme?.launcher
                                  ? handleLauncher(theme?.launcher)
                                  : "N/A"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Spin>
                  </TabPanel>
                  <TabPanel>
                    <Spin spinning={tadLoad} size="medium">
                      <div className="csat_toggle d_flx">
                        <h3>Allow Tunixbot to send all fallback emails</h3>
                        <label className="switch">
                          <input type="checkbox" checked={fallback?.status} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </Spin>
                  </TabPanel>
                  <TabPanel>
                    <Spin spinning={tadLoad} size="medium">
                      <div className="csat_toggle d_flx">
                        <h3>Ask your users for CSAT ratings</h3>
                        <label className="switch">
                          <input type="checkbox" checked={csat?.status} />
                          <span className="slider round"></span>
                          {csat?.status}
                        </label>
                      </div>
                    </Spin>
                  </TabPanel>
                  <TabPanel>
                    <Spin spinning={tadLoad} size="medium">
                      <div className="table_box">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Server key</th>
                            </tr>
                          </thead>
                          {serverKey ? (
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>{serverKey}</td>
                              </tr>
                            </tbody>
                          ) : (
                            <Empty />
                          )}
                        </table>
                      </div>
                    </Spin>
                  </TabPanel>
                  <TabPanel>
                    <Spin spinning={tadLoad} size="medium">
                      <div className="table_box">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Mon</th>
                              <th>Tue</th>
                              <th>Wed</th>
                              <th>Thus</th>
                              <th>Fri</th>
                              <th>Sat</th>
                              <th>Sun</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shift?.length ? (
                              <tr>
                                <td>1</td>
                                {shift?.map((data) => (
                                  <td>
                                    {data?.start_time && data?.end_time
                                      ? `${convertTimeTo12HourFormat(
                                          data?.start_time
                                        )}/${convertTimeTo12HourFormat(
                                          data?.end_time
                                        )}`
                                      : "Not Available"}
                                  </td>
                                ))}
                              </tr>
                            ) : (
                              <Empty />
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Spin>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};
export default ClientDetails;

// Development
// export const API_URL = "https://dev-ai-chatbot.webdevelopmentsolution.net/";

// Staging
export const API_URL = "https://chatbot.webdevelopmentsolution.net/";

export const END_POINTS = {
  // #super admin
  logIn: "super-admin/login-super-admin/",
  userFgtPass: "authentication/user/forgot-password-email/",
  userFgtPassOtp: "authentication/forgot-password-verification/otp/",
  newPassword: "authentication/otp/reset-password/",
  uploadMedia: "authentication/upload/media/",
  changePassword: "authentication/user/change-password/",
  sAdminEditProfile: "super-admin/edit-profile/",
  getSadminData: "super-admin/get-super-admin/",
  superAdminLogout: "authentication/auth/logout",
  // #dashboard
  dashboardCardData: "super-admin/get-all-dashboard-data/",
  dashBrdAllClientData: "super-admin/get-all-client-dashboard/",
  dashBrdTotalRev: "super-admin/get-all-revenue-dashboard/",
  dasDBrdTotalSubs: "super-admin/get-all-subscription-dashboard/",
  dashBrdNewClient: "super-admin/get-new-client-dashboard/",
  // #Bot
  createBotTemplate: "super-admin/create-bot-template/",
  allBotTemplate: "super-admin/get-all-bot-template/",
  deleteBot: "super-admin/delete-bot-template-by-id/",
  botById: "super-admin/get-bot-template-by-id/",
  editBotById: "super-admin/update-bot-template/",
  // #Client
  addClient: "super-admin/add-client/",
  deleteClient: "super-admin/delete-client-by-id/",
  allClient: "super-admin/get-all-client/",
  clientById: "super-admin/get-client-by-id/",
  editClient: "super-admin/update-client-by-id/",
  getTrialPeriod: "super-admin/get-all-trial-period/",
  getClientList: "super-admin/get-filter-all-client/",
  // #Client view
  subscriptionHis: "super-admin/get-client-subscription-history/",
  serverKey: "super-admin/get-server-key/",
  fallbackEmail: "super-admin/get-fallback-email/",
  getCsat: "super-admin/get-csat/",
  shiftTimig: "super-admin/get-agent-shift-timings-of-client/",
  themeManagement: "super-admin/get-theme-management/",
  getClientAgents: "super-admin/get-all-agent-by-client-id/",
  getCustomerList: "super-admin/get-customer-client-listing/",
  // #chat history
  chatHistory: "agent/display-previous-chats/",
  // #Subscription
  createSubscription: "super-admin/create-subscription/",
  deleteSubscription: "super-admin/delete-subscription-by-id/",
  allSubscription: "super-admin/get-all-subscription/",
  sbsById: "super-admin/get-subscription-by-id/",
  editSubscription: "super-admin/update-subscription/",
  getDuration: "super-admin/get-all-duration-period/",
  getGracePeriod: "super-admin/get-all-grace-period/",
  // #Bot Avatar
  addBotAvatar: "super-admin/add-bot-avatar/",
  getBotAvatar: "super-admin/get-all-bot-avatar/",
  deleteAvatar: "super-admin/delete-bot-avatar-by-id/",
  // #Add SubAdmin
  addSubAdmin: "super-admin/add-sub-admin/",
  deleteSubAdmin: "super-admin/delete-sub-admin-by-id/",
  allSubAdmin: "super-admin/get-all-sub-admin/",
  subAdminById: "super-admin/get-sub-admin-by-id/",
  editSubAdmin: "super-admin/update-sub-admin-by-id/",
  moduleListing: "super-admin/get-all-admin/",
  blockSubAdmin: "super-admin/update-block-sub-admin-by-id/",
  // #Bot
  deleteClientBot: "super-admin/delete-manage-bot-by-id/",
  ClientBot: "super-admin/get-all-manage-bot/",
  // #Notification
  addNotification: "/push-notification/add-notification/",
  getAllN_tn: "push-notification/get-all-notification/",
  // #Rating by customer
  getRatingByCust: "/super-admin/get-all-customer-rating/",
  deleteRating: "super-admin/delete-rating-by-id/",
  // #Manage customer
  getCustomer: "super-admin/get-all-customer/",
  // # header notification
  headerNotification: "push-notification/get-all-header-notification/",
  // #customer support
  getCustomerSupport: "authentication/get-all-queries/",
};

import { isEmail } from "../utils/validations";

const Email = ({ value, onChange, require, onKeyDown }) => {
  const showError = (errorMessage) => {
    return (
      <p
        style={{
          color: "red",
          fontSize: 15,
        }}
      >
        {errorMessage}
      </p>
    );
  };

  const validateInput = () => {
    if (require) {
      if (!value) {
        return showError("Please enter Email");
      } else if (!isEmail(value)) {
        return showError("Please enter valid Email");
      }
    }
    if (value) {
      if (!isEmail(value)) {
        return showError("Please enter valid Email");
      }
    }
    return null;
  };

  return (
    <div className="input_box">
      <figure className="sms">
        <img src="/images/sms.svg" alt="customer"></img>
      </figure>
      <input
        type="text"
        placeholder="Email"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {validateInput()}
    </div>
  );
};
export default Email;

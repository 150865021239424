import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";


const notificationApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        hdrNotification: builder.query({
            query: () => ({
                url: END_POINTS.headerNotification,
                method: 'GET',
            }),
        }),
        allNotification: builder.mutation({
            query: (body) => ({
                url: END_POINTS.getAllN_tn,
                method: 'POST',
                body,
            }),
        }),
        addNotictn: builder.mutation({
            query: (body) => ({
                url: END_POINTS.addNotification,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const
    {
        useLazyHdrNotificationQuery,
        useAllNotificationMutation,
        useAddNotictnMutation,
    } = notificationApi;

import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const ClntBotApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        delClientBot: builder.mutation({
            query: (clientId) => ({
                url: `${END_POINTS.deleteClientBot}${clientId}/`,
                method: 'DELETE',
            }),
        }),
        ClientBot: builder.mutation({
            query: (body) => ({
              url: END_POINTS.ClientBot,
              method: 'POST',
              body,
            }),
          }),
    }),
});

export const
    {
        useDelClientBotMutation,
        useClientBotMutation,
    } = ClntBotApi;

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

const { confirm } = Modal;

const DeleteModal = (handleDelete,name) => {
    confirm({
        title: `Are you sure delete this ${name}?`,
        icon: <ExclamationCircleFilled />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
            console.log('OK');
            handleDelete()
        },
        onCancel() {
            console.log('Cancel');
        },
    });
};
export default DeleteModal;
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import Modal from "react-modal";
import LabelInput from "./label";
import InputBox from "./inputbox";
import { errorToast, successToast } from "../helpers/showToast";
import { Spin } from "antd";
import { useAddNotictnMutation } from "../services/notifications";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const AddNotification = ({ setOpen, open }) => {

  function afterOpenModal() { }

  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("");
  const [sendTo, setSendTo] = useState(1);
  const [Ntfntype, seTNfntType] = useState(1);
  const [addNtfn] = useAddNotictnMutation();
  const [require, setRequire] = useState(false);
  const [load, setLoad] = useState(false)

  function closeModal() {
    setOpen(false);
  }

  const submit = async () => {
    if (!title || !message || !sendTo || !Ntfntype) {
      console.log("Please enter all fields");
      setRequire(true)
      return;
    }
    try {
      setLoad(true)

      let body = {
        title: title,
        message: message,
        notification_for: JSON.parse(sendTo),
        notification_type: JSON.parse(Ntfntype),
      }
      const response = await addNtfn(body);
      console.log('response: ', response);
      successToast(response.data.message)
      setOpen(false);
    } catch (error) {
      errorToast(error)
      console.log('error: ', error);
    }
    finally {
      setLoad(false)
      setRequire(false)
      setTitle('');
      setMessage('');
      setSendTo(1);
      seTNfntType(1)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submit(e);
    }
  };

  return (
    <div>
      <Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Spin
          spinning={load}
          size="medium">
          <div
            className="subscription_modal">
            <div
              className="modal_head d_flx">
              <h2>
                Add Notification
              </h2>
              <i
                onClick={closeModal}
                className="fa-solid fa-xmark"></i>
            </div>
            <div className="add_notification">
              <div className="field_slct">
                <LabelInput Name="Notification Send :" />
                <div className="input_box">
                  <select name="client" id="client"
                    value={sendTo}
                    onChange={(e) =>
                      setSendTo(e.target.value)}
                    onKeyDown={handleKeyPress}
                  >
                    <option value="1">All</option>
                    <option value="2">Client</option>
                    <option value="3">Customer</option>
                  </select>
                  {require ? (
                    !sendTo
                    && <p
                      style={{
                        color: 'red',
                        fontSize: 15,
                        paddingBottom: 2,
                        margin: 0
                      }} >Please select Notification Send</p>
                  ) : null
                  }
                </div>
              </div>
              <div className="field_slct">
                <LabelInput
                  Name="Notification Type :" />
                <div className="input_box">
                  <select name="client" id="client"
                    value={Ntfntype}
                    onChange={(e) =>
                      seTNfntType(e.target.value)}
                    onKeyDown={handleKeyPress}
                  >
                    <option value="1">Both</option>
                    <option value="2">Email</option>
                    <option value="3">Push Notification</option>
                  </select>
                  {require ? (
                    !Ntfntype
                    && <p
                      style={{
                        color: 'red',
                        fontSize: 15,
                        paddingBottom: 2,
                        margin: 0
                      }} >Please select Notification Type</p>
                  ) : null
                  }
                </div>
              </div>
              <div className="field_slct">
                <LabelInput
                  Name="Notification Title :" />
                <InputBox
                  placeholder="Title"
                  type="text"
                  require={require}
                  value={title}
                  onChange={(e) =>
                    setTitle(e.target.value)}
                  onKeyDown={handleKeyPress} />
              </div>
              <div className="field_slct">
                <LabelInput
                  Name="Notification Message  :" />
                <textarea
                  placeholder="Message"
                  value={message}
                  onChange={(e) =>
                    setMessage(e.target.value)}
                  onKeyDown={handleKeyPress} />
                {require ? (
                  !message
                  && <p
                    style={{
                      color: 'red',
                      fontSize: 15,
                      paddingBottom: 2,
                      margin: 0
                    }} >Please select Grace Period</p>
                ) : null
                }
              </div>
              <button
                type="button"
                className="save_btn"
                onClick={submit}>
                Submit
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
export default AddNotification;

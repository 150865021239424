import { useState } from "react";
import { Link } from "react-router-dom";
import usePermission from "../../hooks/usePermission";

const SubSidebar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const userRole = usePermission();

  console.log(":::::", userRole);

  return (
    <>
      <div className={props.activeCls ? "sidebar active" : "sidebar"}>
        <figure className="logo_sidebar">
          <img src="/images/logo_sidebar.svg" alt="logo"></img>
        </figure>
        <ul>
          {userRole[0]?.module_name === "Dashboard" && userRole[0]?.is_view && (
            <li
              className={
                window.location.pathname === "/dashboard" ||
                window.location.pathname === "/createbot"
                  ? "active"
                  : ""
              }
            >
              <Link to="/dashboard">
                <figure>
                  <img src="/images/dashboard.svg" alt="logo"></img>
                </figure>
                <span>Dashboard</span>
              </Link>
            </li>
          )}
          {userRole[1]?.module_name === "Manage Subscription" &&
            userRole[1]?.is_view && (
              <li
                className={
                  window.location.pathname === "/subscription"
                    ? "active"
                    : "" || window.location.pathname === "/addsubscription"
                    ? "active"
                    : ""
                }
              >
                <Link to="/subscription">
                  <figure>
                    <img src="/images/subscription.svg" alt="logo"></img>
                  </figure>
                  <span>Manage Subscriptions</span>
                </Link>
              </li>
            )}
          {userRole[2]?.module_name === "Manage Client" &&
            userRole[2]?.is_view && (
              <li
                className={
                  window.location.pathname === "/clients"
                    ? "active"
                    : "" || window.location.pathname === "/viewclient"
                    ? "active"
                    : "" || window.location.pathname === "/addclients"
                    ? "active"
                    : "" || window.location.pathname === "/managechat"
                    ? "active"
                    : ""
                }
              >
                <Link to="/clients">
                  <figure>
                    <img src="/images/people.svg" alt="logo"></img>
                  </figure>
                  <span>Manage Clients</span>
                </Link>
              </li>
            )}
          {userRole[3]?.module_name === "Manage Customer" &&
            userRole[3]?.is_view && (
              <li
                className={
                  window.location.pathname === "/customer"
                    ? "active"
                    : "" || window.location.pathname === "/addcustomer"
                    ? "active"
                    : ""
                }
              >
                <Link to="/customer">
                  <figure>
                    <img src="/images/user_n.svg" alt="logo"></img>
                  </figure>
                  <span>Manage Customers</span>
                </Link>
              </li>
            )}
          {userRole[4]?.module_name === "Manage Bot Template" &&
            userRole[4]?.is_view && (
              <li
                className={
                  window.location.pathname === "/bottemplates"
                    ? "active"
                    : "" || window.location.pathname === "/addbottemplates"
                    ? "active"
                    : ""
                }
              >
                <Link to="/bottemplates">
                  <figure>
                    <img src="/images/customer_support.svg" alt="logo"></img>
                  </figure>
                  <span>Manage Bot Templates</span>
                </Link>
              </li>
            )}
          {userRole[5]?.module_name === "Manage Bot" &&
            userRole[5]?.is_view && (
              <li
                className={window.location.pathname === "/bots" ? "active" : ""}
              >
                <Link to="/bots">
                  <figure>
                    <img src="/images/robot.svg" alt="logo"></img>
                  </figure>
                  <span>Manage Bots </span>
                </Link>
              </li>
            )}
          {userRole[7]?.module_name === "Manage Rating" &&
            userRole[7]?.is_view && (
              <li
                className={
                  window.location.pathname === "/ratings" ? "active" : ""
                }
              >
                <Link to="/ratings">
                  <figure>
                    <img src="/images/rating.svg" alt="logo"></img>
                  </figure>
                  <span>Manage Ratings</span>
                </Link>
              </li>
            )}
          {userRole[8]?.module_name === "Customer Support" &&
            userRole[8]?.is_view && (
              <li
                className={
                  window.location.pathname === "/support" ? "active" : ""
                }
              >
                <Link to="/support">
                  <figure>
                    <img src="/images/customer_support.svg" alt="logo"></img>
                  </figure>
                  <span>Customer Support</span>
                </Link>
              </li>
            )}
          <li>
            <Link onClick={() => setIsOpen(!isOpen)}>
              <figure>
                <img src="/images/settings.svg" alt="logo"></img>
              </figure>
              <span className="st_clp">
                Setting <i className="fa-solid fa-angle-down"></i>
              </span>
            </Link>
            {isOpen && (
              <ul className="sub_menu">
                {userRole[9]?.module_name === "Notification" &&
                  userRole[9]?.is_view && (
                    <li
                      className={
                        window.location.pathname === "/notification"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/notification">
                        <figure>
                          <img
                            src="/images/notification-bing3.svg"
                            alt="logo"
                          ></img>
                        </figure>
                        <span>Notification</span>
                      </Link>
                    </li>
                  )}
                <li
                  className={
                    window.location.pathname === "/editprofile" ? "active" : ""
                  }
                >
                  <Link to="/editprofile">
                    <figure>
                      <img src="/images/info.svg" alt="logo"></img>
                    </figure>
                    <span>Basic Info</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </>
  );
};
export default SubSidebar;

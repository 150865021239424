import { isEmail, validMobileNo } from "../utils/validations";

const InputBox = ({
  placeholder,
  type,
  value,
  onChange,
  require,
  required,
  disabled,
  phone,
  onKeyDown,
  reqValue,
}) => {
  const showError = (errorMessage) => {
    return <p style={{ color: "red", fontSize: 15 }}>{errorMessage}</p>;
  };

  const validateInput = () => {
    if (require) {
      if (!value) {
        return showError(reqValue ? reqValue : `*Please Enter ${placeholder}`);
      } else if (placeholder === "Email" && !isEmail(value)) {
        return showError(`*Please enter valid ${placeholder}`);
      } else if (placeholder === "Phone Number" && !validMobileNo(value)) {
        return showError(`*Please enter valid ${placeholder}`);
      }
    } else if (required) {
      if (!value) {
        return showError(`*Please ${placeholder}`);
      } else if (placeholder === "Enter your Email" && !isEmail(value)) {
        return showError(`*Please enter valid email`);
      } else if (
        placeholder === "Enter your contact number" &&
        !validMobileNo(value)
      ) {
        return showError(`*Please enter valid contact number}`);
      }
    } else if (phone) {
      if (
        placeholder === "Enter your contact number" &&
        !validMobileNo(value)
      ) {
        return showError(`*Please enter valid contact number}`);
      }
    }
    if (value) {
      if (placeholder === "Email" && !isEmail(value)) {
        return showError(`*Please enter valid email`);
      }
      if (placeholder === "Phone Number" && !validMobileNo(value)) {
        return showError(`*Please enter valid contact number`);
      }
    }
    return null;
  };

  return (
    <div className="input_box pd_input">
      <input
        style={{
          cursor: disabled ? "not-allowed" : "auto",
        }}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
      {validateInput()}
    </div>
  );
};
export default InputBox;

const SelectBox = ({ value, onChange }) => {
  return (
    <div className="input_box">
      <select name="client" id="client" value={value?.id} onChange={onChange}>
        <option value="">Select Client</option>
        {value?.map((client) => (
          <option value={client.id} key={client.id}>
            {client.first_name}
          </option>
        ))}
      </select>
    </div>
  );
};
export default SelectBox;

import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "./sidebar";
import useAuth from "../hooks/useAuth";
import SubSidebar from "./sub-admin-sider";

const Layout = ({ children }) => {
  const [activeCls, setActiveCls] = useState(false);
  const useData = useAuth();
  console.log("useData: ", useData?.role);

  const renderSidebar = () => {
    if (useData?.role === 4) {
      return <SubSidebar setActiveCls={setActiveCls} activeCls={activeCls} />;
    } else {
      return <Sidebar setActiveCls={setActiveCls} activeCls={activeCls} />;
    }
  };
  return (
    <div>
      {window.location.pathname === "/" ||
      window.location.pathname === "/forgotpassword" ||
      window.location.pathname === "/resetpassword" ? (
        ""
      ) : (
        <Header setActiveCls={setActiveCls} activeCls={activeCls} />
      )}
      {window.location.pathname === "/" ||
      window.location.pathname === "/forgotpassword" ||
      window.location.pathname === "/resetpassword"
        ? ""
        : renderSidebar()}
      {children}
      <Footer />
    </div>
  );
};

export default Layout;

import React from "react";
import Layout from "../../layout";
import FormForgot from "./features/form";

function ForgotPassword() {
  return (
    <Layout>
        <FormForgot />
    </Layout>
  );
}

export default ForgotPassword;

const FormHead = ({ title }) => {
  return (
    <div className="form_head text_center">
      <figure>
        <img src="/images/logo_black.svg" alt="customer"></img>
      </figure>
      <h1>{title}</h1>
    </div>
  );
};
export default FormHead;

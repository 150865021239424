import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import ManageClients from "./pages/manageclients";
import ManageSubscription from "./pages/managesubscription";
import ManageBots from "./pages/managebots";
import Support from "./pages/support";
import SubAdmin from "./pages/subadmin";
import ManageRatings from "./pages/manageratings";
import Notification from "./pages/notification";
import EditProfile from "./pages/editprofile";
import ManageCustomer from "./pages/managecustomer";
import ManageBotsTemplate from "./pages/managebotstemplate";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";
import ManageChat from "./pages/managechat";
import AddSubscription from "./pages/addsubscription";
import AddClients from "./pages/addclient";
import AddBotTemplate from "./pages/addbottemplate";
import AddSubAdmin from "./pages/addsubadmin";
import ViewClient from "./pages/viewclient";
import ManageAvatar from "./pages/managebotavatar";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/clients" element={<ManageClients />} />
        <Route path="/addclients" element={<AddClients />} />
        <Route path="/viewclient" element={<ViewClient />} />
        <Route path="/customer" element={<ManageCustomer />} />
        <Route path="/subscription" element={<ManageSubscription />} />
        <Route path="/addsubscription" element={<AddSubscription />} />
        <Route path="/bots" element={<ManageBots />} />
        <Route path="/avatar" element={<ManageAvatar />} />
        <Route path="/support" element={<Support />} />
        <Route path="/subadmin" element={<SubAdmin />} />
        <Route path="/addsubadmin" element={<AddSubAdmin />} />
        <Route path="/ratings" element={<ManageRatings />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/bottemplates" element={<ManageBotsTemplate />} />
        <Route path="/addbottemplates" element={<AddBotTemplate />} />
        <Route path="/managechat/:id" element={<ManageChat />} />
      </Routes>
    </>
  );
};

export default Router;

import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import FormLogin from "./features/form";
import { getData } from "../../constants";
import { STORAGE_KEYS } from "../../constants/storage";
import jwt_decode from 'jwt-decode';
import { useNavigate } from "react-router-dom";

function Login() {

  return (
    <Layout>
        <FormLogin />
    </Layout>
  );
}

export default Login;

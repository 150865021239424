import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const botTemplete = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        crteBotTemplt: builder.mutation({
            query: (body) => ({
              url: END_POINTS.createBotTemplate,
              method: 'POST',
              body,
            }),
          }),
        botById: builder.query({
            query: (botId) => ({
                url: `${END_POINTS.botById}${botId}/`,
                method: 'GET',
            }),
        }),
        DelBotTemplt: builder.mutation({
            query: (botId) => ({
                url: `${END_POINTS.deleteBot}${botId}/`,
                method: 'DELETE',
            }),
        }),
        editBotTemplt: builder.mutation({
            query: ({botId, body}) => ({
                url: `${END_POINTS.editBotById}${botId}/`,
                method: 'PUT',
                body
            }),
        }),
        allBotTemplt: builder.mutation({
            query: (body) => ({
              url: END_POINTS.allBotTemplate,
              method: 'POST',
              body,
            }),
          }),
    }),
});

export const
    {
        useCrteBotTempltMutation,
        useLazyBotByIdQuery,
        useDelBotTempltMutation,
        useEditBotTempltMutation,
        useAllBotTempltMutation,
    } = botTemplete;
import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const manageCustApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getCustDetail: builder.mutation({
            query: (body) => ({
                url: END_POINTS.getCustomer,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const
    {
     useGetCustDetailMutation,  
    } = manageCustApi;

/* eslint-disable jsx-a11y/img-redundant-alt */
import { useGetCustDetailMutation } from "../../../services/managecustomer";
import { useLazyClntListingQuery } from "../../../services/client";
import SearchBox from "../../../components/search";
import SelectBox from "../../../components/filter";
import React, { useCallback, useEffect, useState } from "react";
import { Empty, Pagination, Skeleton } from "antd";

const Customer = () => {
  const [load, setLoad] = useState(false);
  const [cust, setCust] = useState([]);
  const [current, setCurrent] = useState();
  const [client, setClient] = useState([]);
  const [clientId, setClientId] = useState();
  const [custData] = useGetCustDetailMutation();
  const [clientName] = useLazyClntListingQuery();

  const [totalCnt, setTotalCnt] = useState();

  const onChange = (page) => {
    setCurrent(page);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        client_id: clientId ? clientId : "",
        page: current,
        length: 10,
      };
      const response = await custData(body);
      console.log("response: ", response.data.data);
      setCust(response.data.data);
      setTotalCnt(response.data.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [current, clientId, custData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        setLoad(true);
        let body = {
          page: 1,
          length: "",
          search_value: "",
        };
        const response = await clientName(body);
        console.log("response: ", response.data.data);
        setClient(response.data.data);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setLoad(false);
      }
    };
    fetchClientData();
  }, [clientName]);

  const filterByClient = async (event) => {
    const clientId = event.target.value;
    setClientId(clientId);
    console.log("clientId: ", clientId);
    if (!clientId) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        client_id: clientId ? clientId : "",
      };
      const response = await custData(body);
      console.log("response: ", response.data.data);
      setCust(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  const handleSearch = async (event) => {
    const srchBot = event.target.value;
    if (!srchBot) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        client_id: clientId ? clientId : "",
        search_value: srchBot ? srchBot : "",
      };
      const response = await custData(body);
      console.log("response: ", response.data.data);
      setCust(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1>Manage Customer</h1>
          <div className="srch_add">
            <SelectBox onChange={filterByClient} value={client} />
            <SearchBox onChange={handleSearch} />
          </div>
        </div>
        <div className="table_box">
          <Skeleton loading={load} active block={true}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Client Name</th>
                </tr>
              </thead>
              <tbody>
                {cust?.length ? (
                  cust?.map((subs, index) => (
                    <tr key={subs?.id}>
                      <td>{++index}</td>
                      <td>
                        <figure className="usr_img">
                          <img src="./images/user.jpeg" alt="img" />
                        </figure>
                      </td>
                      <td>
                        {subs?.first_name
                          ? `${subs?.first_name} ${subs?.last_name}`
                          : "N/A"}
                      </td>
                      <td>{subs?.email ? subs?.email : "N/A"}</td>
                      <td>{subs?.phone_no ? subs?.phone_no : "N/A"}</td>
                      <td>{subs?.client_name ? subs?.client_name : "N/A"}</td>
                    </tr>
                  ))
                ) : !load ? (
                  <Empty />
                ) : null}
              </tbody>
            </table>
          </Skeleton>
        </div>
        <div className="pagination">
          {cust?.length && totalCnt > 10 ? (
            <Pagination
              defaultCurrent={1}
              current={current}
              onChange={onChange}
              total={totalCnt}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Customer;

import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";


const manageBotApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        allAvtr: builder.mutation({
            query: (body) => ({
                url: END_POINTS.getBotAvatar,
                method: 'POST',
                body,
            }),
        }),
        addBotAvtr: builder.mutation({
            query: (body) => ({
                url: END_POINTS.addBotAvatar,
                method: 'POST',
                body,
            }),
        }),
        delBotAvtr: builder.mutation({
            query: (BotAvatarId) => ({
                url: `${END_POINTS.deleteAvatar}${BotAvatarId}/`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const
    {
        useAllAvtrMutation,
        useAddBotAvtrMutation,
        useDelBotAvtrMutation,
    } = manageBotApi;
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from "react";
import { Empty, Spin } from "antd";
import { UploadMedia } from "../../../services/uploadmedia";
import { errorToast, successToast } from "../../../helpers/showToast";
import DeleteModal from "../../../components/deletemodal";
import {
  useAddBotAvtrMutation,
  useAllAvtrMutation,
  useDelBotAvtrMutation,
} from "../../../services/botavatar";

const Avatar = () => {
  const [botAvatar] = useAllAvtrMutation();
  const [load, setLoad] = useState();
  const [avatarData, setAvatarData] = useState([]);
  const [addAvatar] = useAddBotAvtrMutation();
  const [DeleteBotAvatar] = useDelBotAvtrMutation();

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: 1,
        length: 10,
        search_value: "",
      };
      const response = await botAvatar(body);
      console.log("response: ", response?.data?.data);
      setAvatarData(response?.data?.data);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [botAvatar]);

  const handleDelete = async (id) => {
    const res = await DeleteBotAvatar(id);
    successToast(res?.data?.message);
    fetchData();
  };

  const createAvatar = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setLoad(true);
        const mediaResponse = await UploadMedia(file);
        const proMedia = mediaResponse.data.find((media) => media.id);
        console.log("proMedia: ", proMedia);
        let body = {
          image: proMedia?.id,
        };
        const response = await addAvatar(body);
        console.log("response: ", response);
        successToast(response?.data?.message);
      } catch (error) {
        errorToast(error);
        console.log("error: ", error);
      } finally {
        setLoad(false);
        fetchData();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Spin spinning={load}>
        <div className="shd_bx w_100 chat_bot">
          <div className="title_bar d_flx">
            <h1>Manage Bot Avatar</h1>
          </div>
          <ul className="upload_bot">
            <li>
              <i className="fa-solid fa-plus"></i>
              <input type="file" onChange={createAvatar} />
            </li>
            {avatarData?.length
              ? avatarData?.map((avatar) => (
                  <li key={avatar?.id}>
                    <i
                      className="fa-solid fa-xmark"
                      onClick={() =>
                        DeleteModal(() => handleDelete(avatar?.id), "Avatar")
                      }
                    ></i>
                    <figure>
                      <img src={avatar?.image?.media_file_url} alt="img" />
                    </figure>
                  </li>
                ))
              : !load && <Empty />}
          </ul>
        </div>
      </Spin>
    </>
  );
};
export default Avatar;

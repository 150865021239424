// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { removeData } from "../constants";
import { STORAGE_KEYS } from "../constants/storage";

const initialState = {
  user: null,
  token: null,
  tempToken: null,
  rolePermission: null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      removeData(STORAGE_KEYS.userData);
      removeData(STORAGE_KEYS.token);
      state.user = null;
      state.token = null;
    },
    setCredentials: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setRolePermission: (state, action) => {
      state.rolePermission = action.payload.rolePermission;
    },
    temporaryToken: (state, action) => {
      state.tempToken = action.payload.tempToken;
    },
  },
});

export const { setCredentials, temporaryToken, resetAuth, setRolePermission } =
  authSlice.actions;

export const getRolePermission = (state) => state.auth.rolePermission;
export const getCurrentUser = (state) => state.auth.user;
export const getToken = (state) => state.auth.token;

export default authSlice.reducer;

import { getMessaging, getToken } from "firebase/messaging";
import { app } from "./firebaseKeys";
import { getData } from "../constants";
import { STORAGE_KEYS, storeData } from "../constants/storage";

const firebaseCloudMessaging = {
  init: async () => {
    try {
      const messaging = getMessaging(app);
      const tokenInLocalForage = await getData(STORAGE_KEYS.fcmToken);
      if (tokenInLocalForage) {
        return tokenInLocalForage;
      }
      const status = await Notification.requestPermission();
      if (status && status === "granted") {
        const fcm_token = await getToken(messaging, {
          vapidKey:
            "BPNsHUX-5kl894GpPfPN8xPzeykqK2YzGecmU0Cs3Pzae7pC0YzQ53dZAr-YnNWGpUpnPZGgfK8i8AI2YhmFqgk",
        });
        if (fcm_token) {
          console.log({ fcm_token });
          await storeData(STORAGE_KEYS.fcmToken, fcm_token);
          return fcm_token;
        }
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  },
};

export { firebaseCloudMessaging };



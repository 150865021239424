import React, { useState } from "react";
import ChangePassword from "../../../components/changepasswordmodal";

const ProfileHead = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="d_flx prf_head">
        <div>
          <h1>Profile</h1>
          <p>Edit your personal detail</p>
        </div>
        <button
          type="button"
          className="add_btn"
          onClick={() => setOpen(true)}
        >
          Change Password
        </button>
      </div>
      <ChangePassword setOpen={setOpen} open={open} />
    </>
  );
};
export default ProfileHead;

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {
  useEffect,
  useState
} from "react";
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import LabelInput from "../../../components/label";
import InputBox from "../../../components/inputbox";
import {
  errorToast,
  successToast
} from "../../../helpers/showToast";
import { Spin } from "antd";
import { DaysCount } from "../../../utils/daysCount";
import {
  useCreateSubsMutation,
  useEditSubscriptionMutation,
  useLazyGetDurationQuery,
  useLazyGetGracePeriodQuery,
  useLazySbsByIdQuery
} from "../../../services/subscription";

const SubscriptionField = () => {
  let history = useNavigate();
  const [name, setName] = useState("");
  const [desc, setDesc] = useState();
  const [duration, setDuration] = useState();
  const [grace_period, setGrace_period] = useState();
  const [agent_count, setAgent_count] = useState();
  const [queries_count, setQueries_count] = useState();
  const [avatar_count, setAvatar_count] = useState();
  const [price, setPrice] = useState();
  const [load, setLoad] = useState(false);
  const [benefitType, setBenefitType] = useState("0");
  const [benefitCount, setBenefitCount] = useState("");
  const [benefitsData, setBenefitsData] = useState({});
  const [durationData, setDurationData] = useState();
  const [graceData, setGraceData] = useState()
  const [require, setRequire] = useState(false);
  const [getDuration] = useLazyGetDurationQuery();
  const [getGrace] = useLazyGetGracePeriodQuery()
  const [SubsById] = useLazySbsByIdQuery()
  const [EditSubscription] = useEditSubscriptionMutation();
  const [createSubscription] = useCreateSubsMutation()
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if (state?.eve === "edit") {
      const fetchData = async () => {
        try {
          setLoad(true)
          const response = await SubsById(state?.key);
          console.log('response: ', response?.data);
          const responseData = response?.data?.data
          setName(responseData?.name);
          setPrice(responseData?.price);
          setDesc(responseData?.description);
          setDuration(responseData?.duration);
          setGrace_period(responseData?.grace_period);
          setAgent_count(responseData?.agent_count);
          setAvatar_count(responseData?.avatar_count);
          setQueries_count(responseData?.queries_count);
          if (responseData?.agent_count) {
            setBenefitsData((prevData) => ({
              ...prevData,
              Agent: responseData?.agent_count,
            }));
          }
          if (responseData?.avatar_count) {
            setBenefitsData((prevData) => ({
              ...prevData,
              Avatar: responseData?.avatar_count,
            }));
          }
          if (responseData?.queries_count) {
            setBenefitsData((prevData) => ({
              ...prevData,
              Queries: responseData?.queries_count,
            }));
          }
        } catch (error) {
          console.log('error: ', error);
        } finally {
          setLoad(false)
        }
      }
      fetchData();
    }

    const fetchDuration = async () => {
      let res
      try {
        res = await getDuration()
        setDurationData(res.data.data)
      } catch (error) {
        console.log('error', error)
      }
    }

    const fetchGrace = async () => {
      let res
      try {
        res = await getGrace()
        setGraceData(res.data.data)
      } catch (error) {
        console.log('error', error)
      }
    }

    fetchDuration()
    fetchGrace()
  }, [SubsById, getDuration, getGrace, state]);



  const saveSubscription = async () => {
    if (state.eve === "edit") {      // for Edit Bot template
      try {
        setLoad(true);
        let body = {
          name: name,
          price: price,
          duration: duration,
          grace_period: grace_period,
          agent_count: agent_count,
          avatar_count: avatar_count,
          queries_count: queries_count,
          description: desc,
        }
        const id = state.key
        const response = await EditSubscription({ body, id });
        console.log('response: ', response);
        successToast(response.data.message)
        history("/subscription");
      } catch (error) {
        errorToast(error)
        console.log('error: ', error);
      } finally {
        setLoad(false);
      }
    } else if (state.eve === 'add') {    // To create Bot template
      if (!name || !desc || !price || !grace_period || !duration || Object.keys(benefitsData).length !== 3) {
        setRequire(true)
        return;
      }
      try {
        setLoad(true);
        let body = {
          name: name,
          price: price,
          duration: duration,
          grace_period: grace_period,
          agent_count: agent_count,
          avatar_count: avatar_count,
          queries_count: queries_count,
          description: desc,
        }
        const response = await createSubscription(body);
        console.log('response: ', response);
        successToast(response?.data?.message)
        history("/subscription");
      } catch (error) {
        errorToast(error)
        console.log('error: ', error);
      }
      finally {
        setLoad(false);
        setName('');
        setDesc('');
        setPrice('')
      }
    }
  }

  function SubscriptionPage() {
    history("/subscription");
  }

  const addBenefit = () => {
    if (!benefitCount || benefitType === "0") {
      errorToast("Please select benefit type and enter count")
      return;
    } else {
      const benefitTypeName =
        benefitType === "1" ? "Agent" : benefitType === "2" ? "Avatar" : "Queries";
      setBenefitsData((prevData) => ({
        ...prevData,
        [benefitTypeName]: benefitCount,
      }));
      if (benefitType === "1") {
        setAgent_count(benefitCount)
      } else if (benefitType === "2") {
        setAvatar_count(benefitCount)
      } else if (benefitType === "3") {
        setQueries_count(benefitCount)
      }
      successToast(`${benefitCount} ${benefitTypeName} is Added`);
      setBenefitCount("");
      setBenefitType("0");
    }
  };

  const handleCount = (e) => {
    const input = e.target.value;
    const number = parseFloat(input);
    if (!isNaN(number)) {
      setBenefitCount(number);
    } else {
      setBenefitCount("");
    }
  }

  const handlePrice = (e) => {
    const input = e.target.value;
    const number = parseFloat(input);
    if (!isNaN(number)) {
      setPrice(number);
    } else {
      setPrice("");
    }
  }

  const handleName = (e) => {
    const input = e.target.value;
    if (typeof input === 'string') {
      setName(input);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      saveSubscription(e);
    }
  };

  return (
    <>
      <div
        className="shd_bx w_100 chat_bot">
        <Spin
          spinning={load}
          size="medium">
          <div
            className="title_bar d_flx">
            <h1>{state.eve === "add"
              ? "Add Subscription" : "Edit Subscription"
            }</h1>
            <div
              className="srch_add">
              <button
                type="button"
                className="add_btn"
                onClick={SubscriptionPage}>
                Back
              </button>
            </div>
          </div>
          <div
            className="add_form d_flx">
            <div
              className="w_49">
              <LabelInput
                Name="Subscription Name :" />
              <InputBox
                placeholder="Subscription Name"
                type="text"
                value={name}
                onChange={handleName}
                require={require}
                onKeyPress={handleKeyPress} />
            </div>
            <div
              className="w_49">
              <LabelInput
                Name="Subscription Price :" />
              <InputBox
                placeholder="Price"
                type="text"
                value={price}
                onChange={handlePrice}
                require={require}
                onKeyPress={handleKeyPress} />
            </div>
            <div
              className="w_49">
              <LabelInput
                Name="Duration :" />
              <div
                className="input_box">
                <select
                  name="client"
                  id="client"
                  value={duration}
                  onChange={(e) =>
                    setDuration(e.target.value)}
                  onKeyDown={handleKeyPress}>
                  <option
                    disabled
                    selected
                    value>
                    Select Duration
                  </option>
                  {durationData?.map((data) =>
                    <option
                      key={data?.id}
                      value={data?.days}>
                      {DaysCount(data?.days)}
                    </option>
                  )}
                </select>
                {require ? (
                  !duration
                  && <p
                    style={{
                      color: 'red',
                      fontSize: 15,
                      paddingBottom: 2,
                      margin: 0
                    }}>
                    Please select duration
                  </p>
                ) : null
                }
              </div>
            </div>
            <div className="w_49">
              <LabelInput Name="Grace Period :" />
              <div className="input_box">
                <select
                  name="client"
                  id="client"
                  value={grace_period}
                  onChange={(e) =>
                    setGrace_period(e.target.value)}
                  onKeyDown={handleKeyPress}>
                  <option
                    disabled
                    selected
                    value>
                    Grace Period
                  </option>
                  {graceData?.map((data) =>
                    <option
                      key={data?.id}
                      value={data?.days}>
                      {DaysCount(data?.days)}
                    </option>
                  )}
                </select>
                {require ? (
                  !grace_period
                  && <p style={{
                    color: 'red',
                    fontSize: 15,
                    paddingBottom: 2,
                    margin: 0
                  }} >
                    Please select Grace Period</p>
                ) : null
                }
              </div>
            </div>
            <div className="w_100 description_text">
              <LabelInput Name="Description :" />
              <textarea
                className="text_area"
                placeholder="Enter Description"
                value={desc}
                onChange={(e) =>
                  setDesc(e.target.value)}
                onKeyDown={handleKeyPress} />
              {require ? (
                !desc
                && <p
                  style={{
                    color: 'red',
                    fontSize: 15,
                    paddingBottom: 2,
                    margin: 0
                  }} >
                  Please enter description
                </p>
              ) : null
              }
            </div>
            <div
              className="w_100 d_flx align_top">
              <div
                className="w_49">
                <LabelInput
                  Name="Benefit Type :" />
                {Object.entries(benefitsData).map(([benefit, count], index) => (
                  <div
                    key={index}>
                    {benefit}:
                  </div>
                ))}
                <div
                  className="input_box">
                  <select
                    name="benefitType"
                    id="benefitType"
                    value={benefitType}
                    onChange={(e) =>
                      setBenefitType(e.target.value)}
                    onKeyDown={handleKeyPress}>
                    <option
                      value="0">
                      Select Benefit Type
                    </option>
                    <option
                      value="1">
                      Agent
                    </option>
                    <option
                      value="2">
                      Avatar
                    </option>
                    <option
                      value="3">
                      Queries
                    </option>
                  </select>
                  {require
                    && Object.keys(benefitsData).length
                    !== 3 && (
                      <p
                        style={{
                          color:
                            'red', fontSize: 15,
                          paddingBottom: 2,
                          margin: 0
                        }}>
                        Please select Benefit Type and Count for all three benefits
                      </p>
                    )}
                </div>
              </div>
              <div className="w_49">
                <LabelInput Name="Count :" />
                {Object.entries(benefitsData).map(([benefit, count], index) => (
                  <div
                    key={index}>
                    {count}
                  </div>
                ))}
                <div
                  className="add_benifit">
                  <InputBox
                    placeholder="Count"
                    type="text"
                    value={benefitCount}
                    onChange={handleCount}
                    onKeyPress={handleKeyPress}
                  />
                  <span
                    className="ad_bnt"
                    onClick={addBenefit} >
                    + Add Benefits
                  </span>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="save_btn"
            onClick={saveSubscription}
          >
            Submit
          </button>
        </Spin>
      </div>
    </>
  );
};
export default SubscriptionField;

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import SearchBox from "../../../components/search";
import { useNavigate } from "react-router-dom";
import { Empty, Pagination, Skeleton } from "antd";
import { successToast } from "../../../helpers/showToast";
import DeleteModal from "../../../components/deletemodal";
import {
  useAllBotTempltMutation,
  useDelBotTempltMutation,
} from "../../../services/bottemplate";

const Template = () => {
  let history = useNavigate();

  const [botTemplate] = useAllBotTempltMutation();
  const [botData, setBotData] = useState([]);
  const [DeleteBot] = useDelBotTempltMutation();
  const [search, setSrchBot] = useState("");
  const [load, setLoad] = useState(false);

  const [totalCnt, setTotalCnt] = useState();
  const [current, setCurrent] = useState();

  const onChange = (page) => {
    setCurrent(page);
  };

  function AddTemplatePage() {
    history("/addbottemplates");
  }

  function editTemplatePage(id) {
    history("/addbottemplates", {
      state: { key: id, eve: "edit" },
    });
  }
  const handleDelete = async (id) => {
    console.log("id: ", id);
    const res = await DeleteBot(id);
    successToast(res.message);
    fetchData();
  };

  const fetchData = async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
        search_value: search ? search : "",
      };
      const response = await botTemplate(body);
      console.log("response: ", response?.data?.data);
      setBotData(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [search, current]);

  const handleSearch = async (event) => {
    const search = event.target.value;
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        search_value: search ? search : "",
      };
      const response = await botTemplate(body);
      console.log("response: ", response?.data?.data);
      setBotData(response?.data?.data);
      setTotalCnt(response?.data?.total_count);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1>Manage Bots Templates</h1>
          <div className="srch_add">
            <SearchBox onChange={handleSearch} />
            <button type="button" className="add_btn" onClick={AddTemplatePage}>
              Add Bots Templates
            </button>
          </div>
        </div>

        <div className="table_box">
          <Skeleton loading={load} active block={true}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {botData?.length ? (
                  botData?.map((bot, index) => (
                    <tr key={bot.id}>
                      <td>{++index}</td>
                      <td>
                        <figure className="bot_img">
                          <img
                            src={
                              bot.bot_service_image
                                ? bot?.bot_service_image?.media_file_url
                                : "./images/bot.png"
                            }
                            alt="bot"
                          />
                        </figure>
                      </td>
                      <td>{bot?.title}</td>
                      <td>
                        <p className="description_bx">
                          {bot?.description ? bot?.description : "N/A"}
                        </p>
                      </td>

                      <td>
                        <ul className="tbl_icon">
                          <li>
                            <button
                              type="button"
                              onClick={() => editTemplatePage(bot?.id)}
                            >
                              <figure>
                                <img src="/images/edit.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                DeleteModal(
                                  () => handleDelete(bot?.id),
                                  bot.title
                                )
                              }
                            >
                              <figure>
                                <img src="/images/delete.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : !load ? (
                  <Empty />
                ) : null}
              </tbody>
            </table>
            {botData?.length && totalCnt > 10 ? (
              <Pagination
                defaultCurrent={1}
                current={current}
                onChange={onChange}
                total={totalCnt}
              />
            ) : null}
          </Skeleton>
        </div>
      </div>
    </>
  );
};
export default Template;

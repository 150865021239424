const { API_URL, END_POINTS } = require("../constants");
            
export const UploadMedia = async (imageObject) => {
  console.log("imageData",imageObject);

  const formData = new FormData();
  formData.append("media", imageObject);

  let headers = {
    Accept: "application/json",
  };

  try {
    const res = await fetch(API_URL + END_POINTS.uploadMedia, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    return response;
  } catch (error) {
    console.log("error===>",error);
    return error;
  }
};

/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Layout from "../../layout";
import ClientDetails from "./features/clientDetails";

const ViewClient = () => {
  return (
    <>
      <Layout>
        <section className="main_area">
          <ClientDetails />
        </section>
      </Layout>
    </>
  );
};
export default ViewClient;

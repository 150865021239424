import { successToast } from "../../helpers/showToast";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import useAuth from "../../hooks/useAuth";
import { resetAuth } from "../../reducers/authSlice";
import Notification from "../../components/notification";
import { useLogoutMutation } from "../../services/api";
import { STORAGE_KEYS, getData, removeData } from "../../constants/storage";

const Header = (props) => {
  const history = useNavigate();

  const [logout] = useLogoutMutation();
  const userData = useAuth();

  const handleLogout = async () => {
    let res;
    try {
      let body = {
        device_type: "Web",
        device_token: getData(STORAGE_KEYS.fcmToken),
      };
      res = await logout(body);
      console.log("res: ", res);
      successToast(res?.data?.message);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      removeData(STORAGE_KEYS.token);
      removeData(STORAGE_KEYS.userData);
      resetAuth();
      history("/");
    }
  };

  const items = [
    {
      label: (
        <div className="drop_down">
          <p className="hdr_logout" onClick={() => history("/editprofile")}>
            {" "}
            <img src="/images/edit-2.svg" alt="notification"></img>
            Edit Profile
          </p>
          <p className="hdr_logout" onClick={handleLogout}>
            {" "}
            <img src="/images/logout.svg" alt="notification"></img>
            Logout
          </p>
        </div>
      ),
      key: "0",
    },
  ];
  return (
    <>
      <div className="main_header">
        <div
          className={
            props.activeCls ? "toggle_sidebar active" : "toggle_sidebar"
          }
          onClick={() => props.setActiveCls(!props.activeCls)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul>
          <li>
            <Notification />
          </li>
          <li>
            <figure className="usr_img" style={{ cursor: "pointer" }}>
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <img
                  src={
                    userData?.profile_picture?.media_file_url
                      ? userData?.profile_picture?.media_file_url
                      : "./images/user.jpeg"
                  }
                  alt="img"
                />
              </Dropdown>
            </figure>
          </li>
        </ul>
      </div>
    </>
  );
};
export default memo(Header);

import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const dashbrdApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    dashBrdAllClientData: builder.query({
      query: ({ start, end }) => ({
        url: `${END_POINTS.dashBrdAllClientData}?interval=custom&start_date=${start}&end_date=${end}`,
        method: "GET",
      }),
    }),
    dashbrdClientOnBasis: builder.query({
      query: (onBasis) => ({
        url: `${END_POINTS.dashBrdAllClientData}?interval=${onBasis}`,
        method: "GET",
      }),
    }),
    dashBrdTotalRev: builder.query({
      query: ({ start, end }) => ({
        url: `${END_POINTS.dashBrdTotalRev}?interval=custom&start_date=${start}&end_date=${end}`,
        method: "GET",
      }),
    }),
    dashbrdTotalRevOnBasis: builder.query({
      query: (onBasis) => ({
        url: `${END_POINTS.dashBrdTotalRev}?interval=${onBasis}`,
        method: "GET",
      }),
    }),
    dashBrdSubsptn: builder.query({
      query: ({ start, end }) => ({
        url: `${END_POINTS.dasDBrdTotalSubs}?interval=custom&start_date=${start}&end_date=${end}`,
        method: "GET",
      }),
    }),
    dashbrdSubsptnOnBasis: builder.query({
      query: (onBasis) => ({
        url: `${END_POINTS.dasDBrdTotalSubs}?interval=${onBasis}`,
        method: "GET",
      }),
    }),
    dashBrdCard: builder.query({
      query: () => ({
        url: END_POINTS.dashboardCardData,
        method: "GET",
      }),
    }),
    dashBrdNewClient: builder.query({
      query: () => ({
        url: END_POINTS.dashBrdNewClient,
        method: "GET",
      }),
    })
  }),
});

export const {
  useLazyDashBrdAllClientDataQuery,
  useLazyDashbrdClientOnBasisQuery,
  useLazyDashBrdTotalRevQuery,
  useLazyDashbrdTotalRevOnBasisQuery,
  useLazyDashBrdSubsptnQuery,
  useLazyDashbrdSubsptnOnBasisQuery,
  useLazyDashBrdCardQuery,
  useLazyDashBrdNewClientQuery,
} = dashbrdApi;

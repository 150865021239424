/* eslint-disable jsx-a11y/img-redundant-alt */
import DeleteModal from "../../../components/deletemodal";
import SearchBox from "../../../components/search";
import { useNavigate } from "react-router-dom";
import { successToast } from "../../../helpers/showToast";
import { DaysCount } from "../../../utils/daysCount";
import React, { useCallback, useEffect, useState } from "react";
import { Empty, Pagination, Skeleton } from "antd";
import {
  useAllClntMutation,
  useDelClientMutation,
  useEditClinetMutation,
} from "../../../services/client";

const Clients = () => {
  let history = useNavigate();
  const [EditClient] = useEditClinetMutation();
  const [clientData, setClientData] = useState([]);
  const [current, setCurrent] = useState();
  const [allClient] = useAllClntMutation();
  const [search, setSrchBot] = useState("");
  const [totalCnt, setTotalCnt] = useState();
  const [load, setLoad] = useState(false);
  const [DeleteClient] = useDelClientMutation();
  function AddClientsPage() {
    history("/addclients");
  }
  function EditClientPage(id) {
    history("/addclients", {
      state: { key: id, eve: "edit", title: "Edit Client" },
    });
  }

  function ViewClientsPage(viewId) {
    history("/viewclient", { state: { key: viewId } });
  }

  const handleDelete = async (id) => {
    const res = await DeleteClient(id);
    successToast(res?.data?.message);
    fetchData();
  };

  const fetchData = useCallback(async () => {
    try {
      setLoad(true);
      let body = {
        page: current,
        length: 10,
      };
      const response = await allClient(body);
      const responseData = response?.data?.data;
      console.log("response: ", responseData);
      setTotalCnt(response?.data?.total_count);
      setClientData(responseData);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  }, [allClient, current]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleActive = async (stat, id, name) => {
    let res;
    try {
      let body = {
        is_active: stat,
      };
      res = await EditClient({ clientId: id, body });
      console.log("response: ", res);
    } catch (error) {
      console.log("error: ", error);
    }
    if (stat) {
      successToast(name + " is activated");
    } else {
      successToast(name + " is deactivated");
    }
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  const handleSearch = async (event) => {
    const search = event.target.value;
    if (!search) {
      fetchData();
      return;
    }
    try {
      setLoad(true);
      let body = {
        search_value: search ? search : "",
      };
      const response = await allClient(body);
      const responseData = response?.data?.data;
      console.log("response: ", responseData);
      setTotalCnt(response?.data?.total_count);
      setClientData(responseData);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoad(false);
    }
  };

  return (
    <>
      <div className="shd_bx w_100 chat_bot">
        <div className="title_bar d_flx">
          <h1>Manage Clients</h1>
          <div className="srch_add">
            <SearchBox onChange={handleSearch} />
            <button type="button" className="add_btn" onClick={AddClientsPage}>
              Add Client
            </button>
          </div>
        </div>
        <div className="table_box">
          <Skeleton loading={load} active block={true}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Trial Period</th>
                  <th>Subscription</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {clientData?.length ? (
                  clientData?.map((client, index) => (
                    <tr key={client?.id}>
                      <td>{++index}</td>
                      <td>
                        <figure className="usr_img">
                          <img
                            src={
                              client?.profile_picture?.media_file_url
                                ? client?.profile_picture?.media_file_url
                                : "./images/user.jpeg"
                            }
                            alt="bot"
                          />
                        </figure>
                      </td>
                      <td>{client?.first_name}</td>
                      <td>
                        {client?.business_info?.company_name
                          ? client?.business_info?.company_name
                          : "N/A"}
                      </td>
                      <td>{client?.email}</td>
                      <td>{client?.phone_no ? client?.phone_no : "N/A"}</td>
                      <td>
                        {client?.trial_period
                          ? DaysCount(client?.trial_period)
                          : "N/A"}
                      </td>
                      <td>
                        {client?.subscription?.name
                          ? client?.subscription?.name
                          : "N/A"}
                      </td>
                      <td>
                        {" "}
                        <label className="switch">
                          <input
                            type="checkbox"
                            defaultChecked={client?.is_active}
                            onChange={(e) =>
                              handleActive(
                                e.target.checked,
                                client?.id,
                                client?.first_name
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <ul className="tbl_icon">
                          <li>
                            <button
                              type="button"
                              onClick={() => ViewClientsPage(client?.id)}
                            >
                              <figure>
                                <img src="/images/eye_n.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() => EditClientPage(client?.id)}
                            >
                              <figure>
                                <img src="/images/edit.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                DeleteModal(
                                  () => handleDelete(client?.id),
                                  client?.first_name
                                )
                              }
                            >
                              <figure>
                                <img src="/images/delete.svg" alt="eye" />
                              </figure>
                            </button>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : !load ? (
                  <Empty />
                ) : null}
              </tbody>
            </table>
          </Skeleton>
        </div>
        <div className="pagination">
          {clientData?.length && totalCnt > 10 ? (
            <Pagination
              defaultCurrent={1}
              current={current}
              onChange={onChange}
              total={totalCnt}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Clients;

import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const clientViewApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    subscriptionHis: builder.query({
      query: (id) => ({
        url: `${END_POINTS.subscriptionHis}${id}/`,
        method: "GET",
      }),
    }),
    fallbackEmail: builder.query({
      query: (id) => ({
        url: `${END_POINTS.fallbackEmail}${id}/`,
        method: "GET",
      }),
    }),
    getCsat: builder.query({
      query: (id) => ({
        url: `${END_POINTS.getCsat}${id}/`,
        method: "GET",
      }),
    }),
    shiftTimig: builder.query({
      query: (id) => ({
        url: `${END_POINTS.shiftTimig}${id}/`,
        method: "GET",
      }),
    }),
    themeManagement: builder.query({
      query: (id) => ({
        url: `${END_POINTS.themeManagement}${id}/`,
        method: "GET",
      }),
    }),
    getClientAgents: builder.query({
      query: (id) => ({
        url: `${END_POINTS.getClientAgents}${id}/`,
        method: "GET",
      }),
    }),
    getCustomerList: builder.mutation({
      query: ({ body, id }) => ({
        url: `${END_POINTS.getCustomerList}${id}/`,
        method: "POST",
        body,
      }),
    }),
    chatHistory: builder.query({
      query: (id) => ({
        url: `${END_POINTS.chatHistory}${id}/`,
        method: "GET",
      }),
    }),
    serverKey: builder.query({
      query: (id) => ({
        url: `${END_POINTS.serverKey}${id}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyFallbackEmailQuery,
  useLazyGetCsatQuery,
  useLazyShiftTimigQuery,
  useLazyThemeManagementQuery,
  useLazySubscriptionHisQuery,
  useLazyGetClientAgentsQuery,
  useGetCustomerListMutation,
  useLazyChatHistoryQuery,
  useLazyServerKeyQuery,
} = clientViewApi;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpjDVWfl37B3tevpGzGwFQEpVPR1iKfGA",
  authDomain: "chatbot-e1d0a.firebaseapp.com",
  projectId: "chatbot-e1d0a",
  storageBucket: "chatbot-e1d0a.appspot.com",
  messagingSenderId: "769262847617",
  appId: "1:769262847617:web:466199e48bfa50cf7caf80"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
import React, { useCallback, useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { Spin } from "antd";
import {
    useLazyDashBrdNewClientQuery,
} from "../../../services/dashbrdApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function NewClient() {

  const [newClient] = useLazyDashBrdNewClientQuery();
  const labels = [];

  const options = {
    responsive: true,
    plugins: {
      legend: false,
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  const [noClient, setNoClient] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
      const handleNewClient = async () => {
          let res;
          try {
              setLoad(true);
              res = await newClient();
              console.log("res: ", res?.data);
              setNoClient(res?.data?.data);
            } catch (error) {
                console.log("error: ", error);
            } finally {
                setLoad(false);
            }
        }
        handleNewClient();
    },[newClient])

  const data = {
    labels,
    datasets: [
      {
        label: "New Client",
        data: noClient,
        borderColor: "#414de1",
        borderWidth: "4",
        pointRadius: "0",
        fill: false,
        lineTension: 0.31645,
        backgroundColor: "#414de1",
        borderCapStyle: "butt",
        borderJoinStyle: "miter",
        pointBorderColor: "#fff", //#ffffff
        pointBackgroundColor: "#28a745",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#ffcc99",
        pointHoverBorderColor: "#f5efef",
        pointHitRadius: 50,
      },
    ],
  };

  return (
    <>
      <div className="graph_chart">
        <h4>New Client</h4>
        <Spin spinning={load} size="medium">
          <Line options={options} data={data} />
        </Spin>
      </div>
    </>
  );
}

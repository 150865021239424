import { END_POINTS } from "../constants";
import emptySplitApi from "../utils/rtk";

const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
    logIn: builder.mutation({
      query: (body) => ({
        url: END_POINTS.logIn,
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation({
      query: (body) => ({
        url: END_POINTS.superAdminLogout,
        method: 'POST',
        body,
      }),
    }),
    userFgtPass: builder.mutation({
      query: (body) => ({
        url: END_POINTS.userFgtPass,
        method: 'POST',
        body,
      }),
    }),
    userFgtPassOtp: builder.mutation({
      query: (body) => ({
        url: END_POINTS.userFgtPassOtp,
        method: 'POST',
        body,
      }),
    }),
    newPassword: builder.mutation({
      query: (body) => ({
        url: END_POINTS.newPassword,
        method: 'POST',
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: 'POST',
        body,
      }),
    }),
    getSadminData: builder.query({
      query: () => ({
        url: END_POINTS.getSadminData,
        method: 'GET',
      }),
    }),
    sAdminEditProfile: builder.mutation({
      query: (body) => ({
        url: END_POINTS.sAdminEditProfile,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const
  { useLogInMutation,
    useLogoutMutation,
    useUserFgtPassMutation,
    useUserFgtPassOtpMutation,
    useNewPasswordMutation,
    useChangePasswordMutation,    
    useLazyGetSadminDataQuery,
    useSAdminEditProfileMutation,
  } = authApi;

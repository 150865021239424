import React from "react";

const SearchBox = ({ onChange }) => {
  return (
    <>
      <div className="input_box">
        <input type="text" placeholder="search" onChange={onChange} />
        <figure className="eye">
          <img src="/images/search-normal.svg" alt="customer" />
        </figure>
      </div>
    </>
  );
};
export default SearchBox;
